// 英文语言包
export const lang = {
  // nav
  navbar: {
    HOME: 'Home',
    LISTING_FINANCING_SERVICE: 'Listing Financing Service',
    PREIPO_INCUBATION_SERVICE: 'Pre-IPO Incubation Service',
    NEWS_CENTER: 'News Center',
    CHINESE: '简体中文',
    ENGLISH: 'English',
    PORTUGAES: 'Portugaes'
  },
  value: {
    PHILOSOPHY: 'Philosophy',
    PHILOSOPHYCENTER: 'Gratitude, altruism, respect, understanding, tolerance',
    OURVALUE: 'Values',
    OURVALUECENTER: 'Concentric, with virtue, peer, share',
    VISION: 'Vision',
    VISIONCENTER: 'Making mass entrepreneurship easier;',
    VISIONCENTER2: 'Let the virtuous rich, the rich virtuous!'
  },
  financing: {
    SERVICES: 'Listing and financing services for Chinese enterprises at home and abroad',
    TRADEABROAD: 'The most effective way to trade abroad',
    VATSPECIAL: 'Overseas exchange',
    MAINLAND: 'Mainland exchange',
    VISION: 'Our Vision',
    INTELLECTUAL: 'Asset securitization',
    MORE: 'more'
  },
  service: {
    TITLE: 'Pre-listing incubation service for enterprises',
    listtitle1: 'Business plan',
    listtitle2: 'New Brand Trends',
    listtitle3: 'New ways of marketing',
    listtitle4: 'New marketing tools',
    listtitle5: 'Brand incubation',
    listtitle6: 'Compliance management',
    listtitle7: 'Private equity investment services',
    MORE: 'more'
  },
  atrocinador: {
    TITLE: 'Alliance of Listed Sponsors',
    MORE: 'more'
  },
  journalism: {
    TITLE: 'News',
    details: 'view details',
    MORE: 'more'
  },
  develop: {
    TITLE: 'Capital path of enterprise development',
    img: require('../assets/service_way_img_1en.png')
  },
  approach: {
    TITLE: 'The most effective way to list overseas'
  },
  Foreign: {
    TITLE: 'Overseas exchange',
    profit: 'Profit requirement: at the discretion of sponsor',
    market: 'Market value requirements: at the discretion of the sponsor',
    other: 'Establishment time: more than half a year',
    profit1: 'Profit requirement: at the discretion of sponsor',
    market1: 'Market value requirement: minimum 5 million euro',
    other1: 'Assets required: IFRS audited financial statements',
    profit2: 'Profit requirement: at the discretion of sponsor',
    market2: 'Market value requirement: minimum 5 million euro',
    other2: 'Assets required: IFRS audited financial statements',
    profit3: 'Profit requirement: $7.5 million ',
    market3: 'Market value required: $50 million',
    profit4: 'Market value required: $75 million',
    market4: 'Assets required: $75 million',
    profit5: 'Profit requirement: positive in the last three years',
    market5: 'and accumulated ≥11 million USD, ≥ 2.2 million USD in the last two years',
    other5: '$2.2 million per year in the last two years',
    profit6: 'Registered capital: more than 700,000 pounds',
    market6: 'Market value required: 25 million pounds',
    profit7: 'Cash flow requirement: HK $30 million',
    market7: 'Market value required: HK $150 million',
    profit8: 'Profit standard: for the last three years',
    market8: 'Hk $50 million or more,',
    other8: 'Market value required: HK $500 million'
  },
  domestic: {
    TITLE: 'Domestic exchange',
    SHENGZHEN: '※ Mainboard:<br/>The accumulated net cash flow of operating activities in the recent three years shall be > 50 million yuan, or the accumulated operating income in the recent three years shall be > 300 million yuan;<br/>Intangible assets shall account for no more than 20% of net assets at the end of the latest period;<br/>The total amount of capital stock before issuance shall be ≥30 million yuan, and the total amount of capital stock after issuance shall be ≥50 million yuan;<br/>The total share capital after issuance shall be less than or equal to 400 million shares, and the proportion of public issuance shall be more than or equal to 25%;<br/>After issuance, the total share capital shall be > 400 million shares, and the proportion of public issuance shall be ≥10%.<br/>※ Gem:<br/>The total capital stock after issuance ≥30 million yuan;<br/>The total share capital after issuance shall be less than or equal to 400 million shares, and the proportion of public issuance shall be more than or equal to 25%;<br/>After issuance, the total share capital shall be > 400 million shares, and the proportion of public issuance shall be ≥10%.',
    SHANGHAI: '※ Mainboard:<br/>The accumulated net cash flow of operating activities in the recent three years shall be > 50 million yuan, or the accumulated operating income in the recent three years shall be > 300 million yuan;<br/>Intangible assets shall account for no more than 20% of net assets at the end of the latest period;<br/>The total amount of capital stock before issuance shall be ≥30 million yuan, and the total amount of capital stock after issuance shall be ≥50 million yuan;<br/>The total share capital after issuance shall be less than or equal to 400 million shares, and the proportion of public issuance shall be more than or equal to 25%;<br/>After issuance, the total share capital shall be > 400 million shares, and the proportion of public issuance shall be ≥10%.<br/>※ Scientific Innovation Board:<br/>The r&d investment accounts for more than 5% of the operating revenue in the recent three years, or the accumulative r&d investment in the recent three years is more than 60 million yuan;<br/>The total capital stock after issuance ≥30 million yuan;<br/>The total amount of capital stock after issuance shall be less than or equal to RMB 400 million, and the proportion of public issuance shall be greater than or equal to 25%;<br/>After issuance, the total capital stock shall be > 400 million yuan, and the proportion of public issuance shall be ≥10%.',
    BEIJIN: '※ Standard 1:<br/>Market value ≥ 200 million yuan;<br/>The net profit of the last two years shall not be less than 15 million yuan and the weighted average return on net assets shall not be less than 8%, or the net profit of the last year shall not be less than 25 million yuan and the weighted average return on net assets shall not be less than 8%.<br/>※ Standard 2:<br/>Market value ≥ 400 million yuan;<br/>The average operating income in the last two years shall be no less than 100 million yuan, and the growth rate of operating income in the last year shall be no less than 30%. The net cash flow generated by operating activities in the last year shall be positive.<br/>※ Standard 3:<br/>Market value ≥ 800 million yuan;<br/>The operating income of the latest year shall be no less than RMB 200 million, and the total r&d investment of the last two years shall account for no less than 8% of the total operating income of the last two years.<br/>※ Standard 4:<br/>Market value ≥ 1.5 billion yuan;<br/>The total r&d investment in the last two years shall not be less than 50 million yuan.'
  },
  // 证券资产化
  security: {
    TITLE: 'Asset securitization',
    center: 'Asset-backed securitization refers to the process of issuing asset-backed securities on the basis of credit enhancement through structured design with the future cash flow of underlying assets as repayment support.',
    entity: 'Real assets',
    entitycenter: 'real estate, machinery and equipment, etc',
    number: 'Digital assets',
    numbercenter: 'data, systems, etc',
    intellectual: 'Intellectual property assets',
    intellectualcenter: 'invention patents, appearance patents, etc',
    property: 'Financial assets',
    propertycenter: 'Accounts receivable'
  },
  case: {
    TITLE: 'Companies take case',
    case1title: 'Sinovation Ventures',
    case1: 'Founded by Dr. Kai-fu Lee, it is dedicated to early stage investment and provides a full range of entrepreneurial nurturing Investment institutions and entrepreneurial platforms aim to cultivate innovative talents and a new generation of high-tech enterprises.',
    case2title: 'Shenzhen Science and technology finance Promotion Association',
    case2: 'An economic community organization voluntarily formed by enterprises and units of shenzhen science and technology industry and financial industry, which adheres to the principle of non-profit and mutual assistance.',
    case3title: 'Forest Lighting',
    case3: 'Set LED packaging and LED application products as one of the comprehensive photoelectric enterprise, with high efficiency production, research and development and testing equipment and advanced production management technology, has become a world famous LED production production enterprises.',
    case4title: 'Zero2IPO Group',
    case4: 'Chinas leading entrepreneurship and investment comprehensive service providers and well-known investment institutions, its business packages.Including: Qingke Entrepreneurship Research Institute, Dune School, project Workshop.',
    case5title: 'Global Mentor Board',
    case5: 'Focus on the global business wisdom sharing and practical mutual help of the intelligent networking company, to the Internet information service business, knowledge sharing services, enterprise ecological services, technology incubator, education and training services, investment financing services, industrial China projects for the main digital national high-tech enterprises.',
    case6title: 'Xiao E',
    case6: 'With knowledge products and user services as the core of the technology service provider, the founding has served more than one million home customers, products include knowledge store, enterprise live, enterprise training, etc., to provide line for enterprises on the operation, commercial realization, private domain operation and other one-stop service.'
  },
  // Copyright
  copyright: {
    CULTURE: 'Finance intelligence, financing, finance dreams, innovation, entrepreneurship, and create the future',
    CONTACT_INFORMATION: 'CONTACT-INFORMATION',
    ADDRESS_TEXT: 'ADDRESS',
    EMAIL: 'EMAIL',
    TEL: 'TEL',
    ADDRESS_DETAIL: 'Unit 1903, Qianhai Shimao Financial Center, No.3040 Xinghai Road, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen'
  },
  // 上市前孵化服务
  pattern: {
    title: 'Business model and business plan services',
    pattrrm1: 'BP tutoring',
    pattrrm1center: 'BPs customized services include one-to-one consultation, project positioning, industry research and analysis, investment highlights extraction, business logic sorting, profit model analysis, valuation advice, development planning, design arrangement, as well as giving entrepreneurs some notes on how to effectively communicate with investors and other content services',
    pattrrm2: 'Business model design',
    pattrrm1center2: 'From strategy to tactics, from cash flow to profit, from sales to marketing, for enterprises from a higher latitude to provide business planning.',
    pattrrm3: 'Business plan',
    pattrrm1center3: 'To help enterprises develop professional business plans, so that enterprises and investors better communication, so that enterprises quickly financing.',
    pattrrm4: 'Roadshow',
    pattrrm1center4: 'Train business plan speakers to be able to speak logically and answer questions on the spot so that they can quickly impress potential investors.'
  },
  // 合规管理
  management: {
    title: 'Compliance management',
    text1: 'Market transactions',
    text2: 'Safety and environmental protection',
    text3: 'The quality of the product',
    text4: 'Workers',
    text5: 'Financial and tax',
    text6: 'Intellectual property rights',
    text7: 'A business partner'
  },
  // 合规管理
  brand: {
    title: 'Brand incubation',
    text1: 'Media matrix construction',
    text2: 'Brand Marketing Inquiry',
    text3: 'Brand reputation building',
    text4: 'Marketing promotion planning',
    text5: 'Activity/conference execution'
  },
  Brandandsales: {
    TITLE: 'Combination of product and sales',
    center: 'Casfi provides enterprises with integrated operation services of product and sales, including brand framework construction, new media operation, brand anchor incubation, platform management, etc., to help enterprises achieve the purpose of brand promotion and product sales, and to accumulate and support data for listing.'
  },
  camp: {
    TITLE: 'New Media Operation Incubation Training Camp',
    center: 'The Casfi New Media Operation Incubation Camp mainly incubates Internet celebrity anchors and short video operators. The course focuses on content framework sorting and personality/brand positioning. Help enterprises and individual entrepreneurs create unique IP through new media to inject vitality into their careers; help enterprises find suitable brand directions, build brand influencers, expand brand awareness, and empower sales.'
  },
  newcenter: {
    menu1: 'all',
    menu2: 'industry information',
    menu3: 'company dynamics',
    menu4: 'project dynamics',
    viewdetails: 'view details',
    return: 'Returns a list of'
  },
  sponsorList: {
    url1: require('../assets/en/union_1en.png'),
    url_mob1: require('../assets/union_mob_1en.png'),
    dialog_url1: require('../assets/avatar1.1.png'),
    title1: 'Simon Lam',
    introduction1: ['Spridzands law firm listed sponsor agent, group consultant', 'President of Nasdaq OMX Nordic High Growth Markets Sponsor China'],
    text1: 'Proficient in cross-border financial law of various stock exchanges, he graduated from RITZ Cesar RITZ University of Hotel Management in Switzerland in the early years, and joined the graduate "Administration" course of JARDINE In Hong Kong. He moved to the UK and became a senior member of the INSTITUTE of Directors (IoD) (1998) and a member of 48 UK groups. In 2004, he completed his postgraduate course in Chinese law at Shenzhen University. He has served as the head of ALLIED DUNBAR PLC, a listed company on the "high-end" main board of the London Stock Exchange, and is now in charge of the European M&A department of China Simu Law Firm. He has coached over 50 successful cases for listing.',
    url2: require('../assets/en/union_2en.png'),
    url_mob2: require('../assets/union_mob_2en.png'),
    dialog_url2: require('../assets/avatar2.1.png'),
    title2: 'David Heron',
    introduction2: ['Nasdaq First North City sponsor', 'Honorary Chairman of Spridzands law (Hong Kong) Limited'],
    text2: 'David Heron has spent 35 years in financial services in the areas of management, sales and distribution, He was a founding partner and managing director of AM Capital in Hong Kong, Chairman of Info Point International, General Manager of E*Trade Securities in the Philippines, Vice President of Sapphire Securities and Prime East Deputy Director of Securities, Managing Director of Hong Kong Grosvenor Executive Financial Management Co., LTD., familiar with private equity transactions, asset management and corporate finance, secondary market stock investment, IPO investment, pre-IPO investment, etc.',
    url3: require('../assets/en/union_3en.png'),
    url_mob3: require('../assets/union_mob_3en.png'),
    dialog_url3: require('../assets/avatar3.1.png'),
    title3: 'Ansis Spridzans',
    introduction3: ['Managing partner of CDO lawyers', 'Founding partner of Spridzands law & Associates'],
    text3: 'Ansis Spridzans has over 17 years of experience in the legal practice and has worked in a number of banking and financial and capital markets mergers and acquisitions. He has played an important role in many landmark MERGERS and acquisitions transactions in the Latvian market, particularly with in-depth knowledge of the legal aspects of financial, investment and capital markets in Eastern Europe and the post-Soviet Region. Anses Sprijens gained extensive experience in administrative, governmental and political processes as chairman of the Privatization Agency.',
    url4: require('../assets/en/union_4en.png'),
    url_mob4: require('../assets/union_mob_4en.png'),
    dialog_url4: require('../assets/avatar4.1.png'),
    title4: 'Mei Xiao bin',
    introduction4: ['The contemporary intellectual businessman', 'Angel investor', 'He He International Group founder'],
    text4: 'As a devout Buddhist with profound cultural literacy, he has served as general manager of many enterprises and participated in the listing operation of large listed companies. He is familiar with the design of equity structure and has rich experience in enterprise management.',
    url5: require('../assets/en/union_5en.png'),
    url_mob5: require('../assets/union_mob_5en.png'),
    dialog_url5: require('../assets/avatar5.1.png'),
    title5: 'Jason Jia',
    introduction5: ['Government and enterprise service expert'],
    text5: 'Jason is the ministry of national entrepreneurial coaches, small and medium-sized entrepreneurial innovation service experts, mentors, guangdong science and technology business incubator association ant road public welfare fund joint sponsors, ants take co-founder, general manager of CASFI, has more than 10 years experience in enterprise services, for hundreds of Chinese companies listed provide entrepreneurial guidance and counseling.',
    url6: require('../assets/en/union_6en.png'),
    url_mob6: require('../assets/union_mob_6en.png'),
    dialog_url6: require('../assets/avatar6.1.png'),
    title6: 'Evan Yu',
    introduction6: ['Brand incubation expert'],
    text6: 'Co-founder of CASFI, entrepreneur mentor of Wuhan Branch of Casfi, Internet entrepreneur for many times, business model planning expert, new media operation expert and senior product manager, with unique understanding and experience of industry trends, consumer trends and national trends.',
    url7: require('../assets/en/union_7en.png'),
    url_mob7: require('../assets/union_mob_7en.png'),
    dialog_url7: require('../assets/avatar7.1.png'),
    title7: 'Jacky Wong',
    introduction7: ['Enterprise organization system, equity incentive, capital incubation expert'],
    text7: 'Founder of MBA China Win Sales System, serving more than 200 small and medium-sized enterprises, focusing on providing enterprises with organizational system, equity incentive, crowdfunding, amoeba and other module services, has excellent expert counseling ability in the fields of enterprise standardization, marketing performance doubling, bond equity and other fields, enabling enterprises to quickly enter the capital market.',
    url8: require('../assets/en/union_8en.png'),
    url_mob8: require('../assets/union_mob_8en.png'),
    dialog_url8: require('../assets/avatar8.1.png'),
    title8: 'Yan Min',
    introduction8: ['Partner, Deheng Law Firm'],
    text8: 'MBA中国赢销体系创始人，服务超过200家中小企业，专注为企业提供组织系统、股权激励、众筹融资、阿米巴等模块服务，在企业规范化、营销业绩倍增、债券股权等领域拥有卓越的专家辅导能力，赋能企业快速进入资本市场。'
  },
  imgList: {
    img1: require('../assets/home_banner.png'),
    img2: require('../assets/banner-ronzienen.png'),
    img3: require('../assets/banner-fuhuaen.png'),
    img4: require('../assets/banner-news-centeren.png')
  },
  abroadbadyimg: {
    img: require('../assets/service_way_img_2aen.png'),
    stepone: 'Step 3',
    steptwo: 'Step 2',
    stepthree: 'Step 1',
    step1text: 'From the US stock exchange board to the main board of Hong Kong Stock Exchange listing',
    step2text: 'IPO on NASDAQ (US) or London Stock Exchange',
    step3text: 'Listing on NASDAQ (Nordic) -CSD and then listing on NASDAQ (Nordic) -First North To pave the way for a future listing'
  },
  ideaurl: {
    img1: require('../assets/home_culture_linian_moben.png'),
    img2: require('../assets/home_culture_value_moben.png'),
    img3: require('../assets/home_culture_forward_moben.png')
  },
  // bp辅导
  bpCoach: {
    // 页面导航
    breadcrumb: {
      toTitle: 'Pre-IPO Incubation Service',
      selfTitle: 'BP tutoring'
    },
    // 标题
    contentTitle: {
      solution: 'BP custom solutions',
      server: 'Service Guarantee We understand investors better',
      custom: 'BP customized service process',
      case: 'BP customized service case',
      organ: 'Cooperative investment institutions'
    },
    // 计划书
    tip: {
      pcUrl: require('../assets/bp/bp_tip_en.png'),
      h5Url: require('../assets/bp/bp_tip_web_en.png')
    },
    // BP定制解决方案
    solution: [
      {
        url: require('../assets/bp/bp_icon_data.png'),
        title: 'Data',
        text: 'Relying on the omni-channel and multi-dimensional primary and secondary market database, it assists customers to explore the huge commercial value hidden behind the data in their project-related fields.'
      },
      {
        url: require('../assets/bp/bp_icon_study.png'),
        title: 'Research',
        text: 'Based on the industry experience of senior analysts,form a full range of market analysis, industry analysis, directional enterprise analysis research results.'
      },
      {
        url: require('../assets/bp/bp_icon_consult.png'),
        title: 'Consult',
        text: 'Senior analysts follow up the whole process, conduct competitive product research, formulate reasonable future development expectations, and make reasonable valuation range suggestions during the service period.'
      },
      {
        url: require('../assets/bp/bp_icon_report.png'),
        title: 'BP Report',
        text: 'Combined with complete investment and financing data and long-term accumulation of analysts, It presents the development of the enterprise from the aspects of enterprise valuation, business logic sorting, profit model analysis, development strategic planning, financial information, etc. Customize the business plan most wanted by investors.'
      }
    ],
    server: {
      pcUrl: [
        { url: require('../assets/bp/bp_service_1_en.png') },
        { url: require('../assets/bp/bp_service_2_en.png') },
        { url: require('../assets/bp/bp_service_3_en.png') }
      ],
      h5Url: [
        { url: require('../assets/bp/bp_service_web_1_en.png') },
        { url: require('../assets/bp/bp_service_web_2_en.png') },
        { url: require('../assets/bp/bp_service_web_3_en.png') }
      ]
    },
    // BP定制服务流程
    custom: {
      pcUrl: require('../assets/bp/bp_custom_en.png'),
      h5Url: [
        {
          url: require('../assets/bp/bp_custom_web_1.png'),
          title: 'Submit Requireme',
          text: 'Project preliminary screening, matching analysts, and demand communication.'
        },
        {
          url: require('../assets/bp/bp_custom_web_2.png'),
          title: 'Consultation and Negotiation',
          text: 'Pre-interview, research and analysis, provide customized solutions, and make reasonable quo-tations according to user needs and service costs.'
        },
        {
          url: require('../assets/bp/bp_custom_web_3.png'),
          title: 'Sign Contract',
          text: 'Contract signing: Sign a formal contract to en-sure service quality, protect customer rights and interests, and issue invoices after payment.'
        },
        {
          url: require('../assets/bp/bp_custom_web_4.png'),
          title: 'Analyst Interview',
          text: '1V1 in-depth interviews with senior analysts, and customized services according to the stage of the project.'
        },
        {
          url: require('../assets/bp/bp_custom_web_5.png'),
          title: 'BP',
          text: 'Project positioning, business logic sorting, profit model analysis, investment highlight extraction, development planning, valuation suggestions, etc'
        }
      ]
    },
    // BP定制服务案例图片
    case: {
      pcUrl: require('../assets/bp/bp_case_en.png'),
      h5Url: [
        { url: require('../assets/bp/bp_case_web_1_en.png') },
        { url: require('../assets/bp/bp_case_web_2_en.png') },
        { url: require('../assets/bp/bp_case_web_3_en.png') },
        { url: require('../assets/bp/bp_case_web_4_en.png') },
        { url: require('../assets/bp/bp_case_web_5_en.png') },
        { url: require('../assets/bp/bp_case_web_6_en.png') }
      ]
    },
    // 合作投资机构
    organ: {
      pcUrl: require('../assets/bp/bp_organ.png'),
      h5Url: require('../assets/bp/bp_organ_web.png')
    }
  },
  // 上市孵化服务新增版块
  incubation: {
    title: 'The registered capital of the enterprise shall be paid in full',
    capitalList: [
      {
        title: 'Subscription VS payment',
        text: 'From March 1, 2014, the registered capital registration system will be replaced by the subscription registration system, and other requirements for industrial and commercial registration will be relaxed. The registered capital of the company to write, in order to highlight the strength of the company.But for this, also buried the risk hidden trouble to the investment shareholder.Article 3 of the Company Law stipulates that "the shareholders of a limited liability company shall be liable to the company within the limit of their subscribed capital contribution; Shareholders of a joint stock limited company shall be liable to the company to the extent of the shares they subscribe for ". Once the debt risk occurs in the operation of the enterprise, the shareholders have subscribed but not actually paid, and the liability of the shareholders shall be investigated according to the subscribed amount.',
        pcUrl: require('@/assets/incubation/capital_pc_1.png'),
        h5Url: require('@/assets/incubation/capital_h5_1.png'),
        isOpen: false
      },
      {
        title: 'The method of payment',
        text: 'Article 27 of the Company Law stipulates that shareholders may make capital contribution in currency, and may also make capital contribution in non-monetary assets such as physical objects, intellectual property rights and land use rights that can be valued in currency and transferred according to law. Therefore, when the shareholders have completed the paid-in obligations of the company, they are naturally stripped of the risk of the company\'s operation. Article 3 of the Company Law stipulates that "the company shall be liable for the debts of the company with all its property." Shareholders do not need to assume additional responsibilities after completing the paid-in obligations.',
        pcUrl: require('@/assets/incubation/capital_pc_2.png'),
        h5Url: require('@/assets/incubation/capital_h5_2.png'),
        isOpen: false
      }
    ],
    childTitle: 'Operation process',
    capitalData: [
      {
        url: require('@/assets/bp/bp_custom_web_1.png'),
        title: 'Sign the contract',
        text: 'Sign the contract and provide capital increase materials.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_2.png'),
        title: 'Finishing materials',
        text: 'Related companies to make relevant screening, extraction and sorting of materials, and evaluation work.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_3.png'),
        title: 'Value evaluation',
        text: 'The appraisal report and capital verification report shall be issued upon completion of the appraisal, which shall be confirmed and signed by senior experts in the industry.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_4.png'),
        title: 'Industrial and commercial audit',
        text: 'The industry and commerce commissioner will take the report to your company for shareholder signature, and to the local industry and commerce for audit, the first and second audit passed, complete the industry and commerce management.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_5.png'),
        title: 'Successful paid-in/ capital increase',
        text: 'Complete the full payment/capital increase.'
      }
    ]
  }
}
