<!--  -->
<template>
  <div class="carousel" >
    <el-carousel :height="height + 'px'" v-if='iscarousel' arrow="never" indicator-position="none">
      <el-carousel-item v-for="(img, index) in imgList" :key="index"
        ><img v-bind:src="img.url" class="carousel-img" />
      </el-carousel-item>
    </el-carousel>
    <div v-if='!iscarousel' class="imgurlbanner">
      <img :src="imgurl"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Carousel',
  data () {
    return {
      imgList: [],
      height: 500,
      iscarousel: false,
      imgurl: ''// 图片路劲参数
    }
  },
  computed: {
    ...mapGetters(['getLangIndex', 'getNavIndex'])
  },
  watch: {
    getLangIndex (to, from) {
      this.getimgUrl()
    }
  },
  methods: {
    getimglist () {
      this.imgList = [
        { url: this.$t('lang.imgList.img1') }
        // { url: this.$t('lang.imgList.img2') },
        // { url: this.$t('lang.imgList.img3') },
        // { url: this.$t('lang.imgList.img4') }
      ]
    },
    // 获取图片的公共方法
    getimgUrl () {
      switch (this.getNavIndex) {
        case 0:
          // this.imgurl = this.$t('lang.imgList.img1')
          this.getimglist()
          this.iscarousel = true
          break
        case 1:
          this.imgurl = this.$t('lang.imgList.img2')
          this.iscarousel = false
          break
        case 2:
          this.imgurl = this.$t('lang.imgList.img3')
          this.iscarousel = false
          break
        case 3:
          this.imgurl = this.$t('lang.imgList.img4')
          this.iscarousel = false
          break
        default:
          this.getimglist()
          this.iscarousel = true
          break
      }
    }
  },
  mounted () {
    this.getimgUrl()
    if (window.innerWidth <= 500) {
      this.height = 150
    } else if (window.innerWidth <= 960) {
      this.height = 350
    } else {
      this.height = 500
    }
  }
}
</script>
<style scoped lang='less'>
.carousel {
  .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.imgurlbanner{
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width:100%
  }
}
</style>
